import React, { createContext, useContext, useEffect, useState, ReactNode } from 'react';

export interface MessageNotificationInterface {
    task: "DISPLAY" | "SYSTEM";
    section: string;
    type: "success" | "error" | "warning";
    message: string;
}

interface Notification {
    id: number;
    message: MessageNotificationInterface;
}

interface NotificationContextType {
    messages: Notification[];
    addMessage: (msg: MessageNotificationInterface) => void;
}

const NotificationContext = createContext<NotificationContextType | undefined>(undefined);

let messageId = 0;

export const NotificationProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const [messages, setMessages] = useState<Notification[]>([]);
    let url = process.env.REACT_APP_WEBSOCKET_URL ?? "";
    useEffect(() => {
        try{
            const conn = new WebSocket(url);
            conn.onopen = () => {
            };

            conn.onmessage = (e: MessageEvent) => {
                try {
                    const data: MessageNotificationInterface = JSON.parse(e.data);
                    const newMessage: Notification = { id: messageId++, message: data };
                    setMessages(prevMessages => [...prevMessages, newMessage]);
                } catch (error) {
                }
            };

            conn.onerror = (error: Event) => {
            };

            conn.onclose = () => {
            };

            // Cleanup the connection when the component is unmounted
            return () => {
                conn.close();
            };
        }
        catch(error: any){
            console.log(error);
        }
    }, []);

    const addMessage = (msg: MessageNotificationInterface) => {
        const newMessage: Notification = { id: messageId++, message: msg };
        setMessages(prevMessages => [...prevMessages, newMessage]);
    };

    return (
        <NotificationContext.Provider value={{ messages, addMessage }}>
            {children}
        </NotificationContext.Provider>
    );
};

export const useNotifications = (): NotificationContextType => {
    const context = useContext(NotificationContext);
    if (!context) {
        throw new Error('useNotifications must be used within a NotificationProvider');
    }
    return context;
};
