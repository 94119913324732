
import AutomationProblematicSchedulesComponent from "../../../components/Automations/ProblematicSchedules/ProblematicSchedules";
import "./ProblematicSchedules.css";

function ProblematicSchedules() {
  return (
    <>
      <div className="shops-container">
        <h3>Automatisation - Recaps</h3>
        <AutomationProblematicSchedulesComponent />
      </div>
    </>
  );
}

export default ProblematicSchedules;
